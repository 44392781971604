<template>
	<v-text-field
		:id="id"
		ref="passwordInput"
		:label="label"
		:loading="loading"
		:disabled="disabled"
		:hide-details="hideDetails"
		outlined
		:rules="rules"
		class="mt-3 pt-0"
		v-model="password"
		:suffix="suffix"
		:placeholder="placeholder"
		v-on:onchange="$emit('onchange', true)"
		v-on:keyup="$emit('keyup', true)"
		:type="show_password ? 'text' : 'password'"
		:append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
		v-on:click:append="show_password = !show_password"
	></v-text-field>
</template>

<script>
export default {
	name: "password-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: null,
		},
		value: {
			type: String,
			default: null,
		},
		suffix: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
	},
	data() {
		return {
			password: null,
			show_password: false,
		};
	},
	watch: {
		value() {
			this.password = this.value;
		},
		password() {
			this.$emit("input", this.password);
		},
	},
	mounted() {
		this.password = this.value;
	},
};
</script>
